import React, {  useState ,useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useHistory, } from 'react-router-dom';
import { useSelector } from "react-redux";

import styles from './Home.module.css';
import Header from '../../components/header/Header';
import { Watchlist } from '../watchlist/Watchlist';
import { Dashboard } from '../dashboard/Dashboard';
import {Dip} from '../dip/Dip';
import {Trend} from '../trend/Trend';
import { selectUser } from '../login/LoginSlice';

const Home = () => {
  const history = useHistory();
  const userObject = useSelector(selectUser);
  const [renderDashboard, setRenderDashboard] = useState(true);
  
  //Validate session.
  useEffect(() => {
   if(!(userObject?.isAuthenticated)) history.push('/login');
  }, [])
  
  /**
   * Function to hide the Dashboard and navigate to an individual feature component.
   * Called when a feature tile is clicked.
   * @param {number} index 
   */
  const goToIndividualFeature=(index)=>{
    console.log(index);
    setRenderDashboard(false);
    switch (index) {
      case 0: history.push('/home/dip');
      break;
      case 1: history.push('/home/trend');
      break;
      
      default: history.push('/home/dip');
      break;
    }
    
  }

  return (
    <div className= {`row ${styles.wrapper}`}>
        <Header/>
        
        <div className='col-12'>
        <div className='row'>
          <div className='col-md-3 '>
              <Watchlist/> 
          </div>
          <div className='col-md-9 '>
            {
              renderDashboard ? <Dashboard navigationCallback={goToIndividualFeature}/> :
              <>
              <div className='breadcrumb' >
                <input type='button' className='btn btn-success bi bi-arrow-return-left' value='Back To Dashboard' onClick={()=> {setRenderDashboard(true); history.push('/home');}}></input>
              </div>
               <Router>
                <Switch>
                  <Route path='/home/dip' component={Dip} exact></Route>
                  <Route path='/home/trend' component={Trend} exact></Route>
                </Switch>
               </Router> 
              </>
            }
              
              
          </div>
        </div>
        </div>
        
       

    </div>
  )
}



export default Home;
