import React, { useState } from "react";
import styles from "./Header.module.css";
import { useSelector,useDispatch } from "react-redux";
import { selectUser,clearSession } from "../../features/login/LoginSlice";
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const Header = (props) => {
  const userObject = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();


  const doLogOut=()=>{
    //clear userObject
    dispatch(clearSession());
    setTimeout(() => {
      history.push('/login');
    }, 0);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header_left}>
        <img src="logo.png" alt="Logo" className={styles.logo} />
        <h1 className={styles.title}> Trader Pro</h1>
      </div>
      <div className={styles.header_right}>
        <span>{userObject?.userDetails?.first_name}</span>
        <img
          src={require("../../assets/image/user.png")}
          alt="Profile Icon"
          className={styles.profile_icon}
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
        {isModalOpen && (
          <div className={`modal fade show ${styles.modal_size}`} style={{ display: "block" }}>
            <div className={`modal-dialog  ${styles.modal_top_right}`}>
              <div className="modal-content">
                <div className="modal-body">
                  <p>My Profile</p>
                  <p onClick={doLogOut}>Logout</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
