import React from 'react';
import styles from './Dashboard.module.css';

export const Dashboard = (props) => {
  const feature_tiles = [{id:0, title:"Invest In Dips",desc:"Some description"},
                         {id:1, title:"Find The Trend",desc:"Some description"}];


  return (
    <div className={`row ${styles.wrapper}`}>
      
      <div className={styles.dashboard_title}>
          <h4>FEATURES</h4>
      </div>
      

      <div className='feature-tile-wrapper col-12'>
        <div className='row'>
          {
          feature_tiles.map((tile,index)=>{
            return(
            <div className="col-md-3" key={index}>
              <div className={`card ${styles.card}`} onClick={()=>props.navigationCallback(index)}>
              <img alt='feature-symbol' src={require('../../assets/image/investment.png')} className={`card-img-left ${styles.iconImage}`}  />
                <div className="card-body" >
                  <h5 className="card-title">{tile.title}</h5>
                  <p className="card-text">{tile.desc}</p>
                </div>
              </div>
            </div>
            )
          })
          }
        </div>
      </div>
      
      
    </div>
  )
}
